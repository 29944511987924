<template>
  <validation-observer ref="messageValidator" #default="{ invalid }">
    <b-form @submit.prevent ref="messageForm">
      <!-- Voice Phone Number Select Field -->
      <b-form-group
        class="voice-phone-number-form-group"
        label="Voice Phone Number"
        label-for="voice-phone-number"
      >
        
        <b-form-input 
          :value="voicePhoneNumber"
          readonly
        />

      </b-form-group>

      <!-- Select All Recipients or Not -->
      <b-form-group
        label="How do you want to choose your recipients"
        v-slot="{ ariaDescribedby }"
        class="mt-2"
      >
        <validation-provider
          name="Choose Your Recipients"
          vid="selectAllContacts"
          rules="required"
          class="d-md-flex justify-content-start align-items-center"
        >
          <b-form-radio
            v-model="selectAllContacts"
            :aria-describedby="ariaDescribedby"
            name="selectAllContacts"
            :value="true"
            >Select all of my contacts ({{ allContactsCount }})</b-form-radio
          >
          <b-form-radio
            v-model="selectAllContacts"
            :aria-describedby="ariaDescribedby"
            name="selectAllContacts"
            :value="false"
            class="ml-md-1 mt-1 mt-md-0"
            >I want to choose my recipients</b-form-radio
          >
        </validation-provider>
      </b-form-group>

      <!-- How Recipients Should Be Added Radio Boxes -->
      <transition name="fade" mode="out-in">
        <b-form-group
          label="Please select your preferred contact entry option"
          v-slot="{ ariaDescribedby }"
          class="mt-2"
          v-if="selectAllContacts === false"
        >
          <validation-provider
            #default="{ errors }"
            name="Recipent Adding Method"
            vid="recipentAddingMethod"
            rules="required"
          >
            <b-row no-gutters>
              <b-col xs="12" md="6" lg="4" xl="3" class="pr-1">
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="phoneNumber"
                  >Write Each Contact Phone Number</b-form-radio
                >
              </b-col>
              <b-col xs="12" md="6" lg="4" xl="3" class="mt-1 mt-md-0 pr-1">
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="contacts"
                  >Choose From Your Contacts</b-form-radio
                >
              </b-col>
              <b-col xs="12" md="6" lg="4" xl="3" class="mt-1 mt-lg-0 pr-1">
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="groups"
                  >Choose Contacts From Your Contact Groups</b-form-radio
                >
              </b-col>
              <b-col xs="12" md="6" lg="4" xl="3" class="mt-1 mt-xl-0">
                <b-form-radio
                  v-model="recipentAddingMethod"
                  :aria-describedby="ariaDescribedby"
                  name="recipentAddingMethod"
                  value="file"
                  >Add Contacts From A File</b-form-radio
                >
              </b-col>
            </b-row>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </transition>

      <!-- Recipent Adding Different Fields  -->
      <transition name="fade" mode="out-in">
        <b-form-group
          label-for="phoneNumbers"
          label="Write Each Contact Phone Number"
          class="mt-2"
          v-if="
            recipentAddingMethod == 'phoneNumber' && selectAllContacts === false
          "
          key="phoneNumbers"
        >
          <b-row>
            <v-select
              v-model="country"
              :options="countries"
              label="text"
              :reduce="(country) => country.value"
              class="country-codes col-md-4 col-lg-3 col-xl-2"
              placeholder="Country Code"
            />

            <validation-provider
              name="Write Each Contact Phone Number"
              vid="phoneNumbers"
              class="col-md-8 col-lg-9 col-xl-10 mt-1 mt-md-0"
            >
              <b-form-tags
                input-id="phoneNumbers"
                name="phoneNumbers"
                v-model.trim="phoneNumbers"
                separator=" ,;"
                placeholder="Add contact phone numbers ..."
                input-type="number"
              ></b-form-tags>
              <small class="text-muted"
                >Press <strong>enter</strong> to add each contact phone
                number</small
              >
            </validation-provider>
          </b-row>
        </b-form-group>

        <b-form-group
          label-for="contacts"
          label="Choose From Your Contacts"
          class="mt-2"
          v-if="
            recipentAddingMethod == 'contacts' && selectAllContacts === false
          "
          key="contacts"
        >
          <validation-provider name="Choose From Your Contacts" vid="contacts">
            <v-select
              v-model="contacts"
              :options="allContacts"
              @search="filterContacts"
              :filterable="false"
              multiple
              placeholder="Search for your contacts through contacts phone number, name, email or there company"
            >
              <span slot="no-options">No contacts found from your search</span>
            </v-select>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="groups"
          label="Choose Contacts From Your Contact Groups"
          class="mt-2"
          v-if="recipentAddingMethod == 'groups' && selectAllContacts === false"
          key="groups"
        >
          <validation-provider
            name="Choose Contacts From Your Contact Groups"
            vid="contacts"
          >
            <v-select
              v-model="groups"
              :options="allgroups"
              label="text"
              :reduce="(group) => group.value"
              multiple
              placeholder="Select Contact Groups"
            >
              <span slot="no-options">No Contact Groups Found</span>
            </v-select>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="mt-2"
          v-if="recipentAddingMethod == 'file' && selectAllContacts === false"
          key="file"
        >
          <label
            for="file"
            class="d-flex align-items-baseline justify-content-between"
          >
            <span>Add Contacts From A File</span>
            <a
              :href="appUrl + '/uploads/samples/pepea_sms_sample_send_sms.xlsx'"
              download
              >Upload Template</a
            >
          </label>
          <b-row>
            <v-select
              v-model="country"
              :options="countries"
              label="text"
              :reduce="(country) => country.value"
              class="country-codes col-md-4 col-lg-3 col-xl-2"
              placeholder="Country Code"
            />
            <validation-provider name="Add Contacts From A File" vid="file" class="col-md-8 col-lg-9 col-xl-10 mt-1 mt-md-0">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    @click="file = null"
                  >
                    Reset
                  </b-button>
                </b-input-group-prepend>
                <b-form-file
                  v-model="file"
                  id="file"
                  input-id="file"
                  placeholder="Choose a .xlsx file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </b-input-group>
              <small class="text-muted"
                >Only accepts file with .xlsx extension</small
              >
            </validation-provider>
          </b-row>
        </b-form-group>
      </transition>

      <!-- How SMS Sending should be scheduled Radio Boxes  -->
      <b-form-group
        label="Select the time you want this message to be sent"
        v-slot="{ ariaDescribedby }"
        class="mt-2"
      >
        <validation-provider
          name="Schedule the message"
          vid="schedule"
          rules="required"
          class="d-flex justify-content-start align-items-center"
        >
          <b-form-radio
            v-model="schedule"
            :aria-describedby="ariaDescribedby"
            name="schedule"
            value="now"
            >Now</b-form-radio
          >
          <b-form-radio
            v-model="schedule"
            :aria-describedby="ariaDescribedby"
            name="schedule"
            value="later"
            class="ml-1"
            >Later</b-form-radio
          >
          <b-form-radio
            v-model="schedule"
            :aria-describedby="ariaDescribedby"
            name="schedule"
            value="repeat"
            class="ml-1"
            >Repeat</b-form-radio
          >
        </validation-provider>
      </b-form-group>

      <!-- SMS Sending Scheduled For Later And Repeat Dates & Time Fields  -->
      <transition name="fade" mode="out-in">
        <b-form-group
          label="Select Later Date & Time"
          class="mt-2"
          label-for="later"
          key="later"
          v-if="schedule == 'later'"
        >
          <flat-pickr
            v-model="later"
            id="later"
            inputId="later"
            class="form-control"
            placeholder="Select Later Date & Time"
            :config="{
              enableTime: true,
              altInput: true,
              altFormat: 'D, M d, Y h:m K',
              dateFormat: 'Z',
              minDate: todayDate,
              clickOpens:true,
            }"
          />
        </b-form-group>

        <div v-if="schedule == 'repeat'">
          <b-form-group
            label="Initial Messaging Date & Time"
            class="mt-2"
            key="initialMessage"
          >
            <flat-pickr
              v-model="initialMessage"
              id="initialMessage"
              inputId="initialMessage"
              placeholder="Initial Messaging Date & Time"
              class="form-control"
              :config="{
                enableTime: true,
                altInput: true,
                altFormat: 'D, M d, Y h:m K',
                dateFormat: 'Z',
                minDate: todayDate,
              }"
            />
          </b-form-group>

          <b-form-group
            label="Repeat Schedule"
            v-slot="{ ariaDescribedby }"
            class="mt-2"
          >
            <validation-provider
              name="Repeat Schedule"
              vid="schedule"
              class="d-flex justify-content-start align-items-center"
            >
              <b-form-radio
                v-model="repeatSchedule"
                :aria-describedby="ariaDescribedby"
                name="repeatSchedule"
                value="Daily"
                >Daily</b-form-radio
              >

              <b-form-radio
                v-model="repeatSchedule"
                :aria-describedby="ariaDescribedby"
                name="repeatSchedule"
                value="Weekly"
                class="ml-1"
                >Weekly</b-form-radio
              >

              <b-form-radio
                v-model="repeatSchedule"
                :aria-describedby="ariaDescribedby"
                name="repeatSchedule"
                value="Monthly"
                class="ml-1"
                >Monthly</b-form-radio
              >

              <b-form-radio
                v-model="repeatSchedule"
                :aria-describedby="ariaDescribedby"
                name="repeatSchedule"
                value="Custom"
                class="ml-1"
                >Custom</b-form-radio
              >
            </validation-provider>
          </b-form-group>

          <transition name="fade" mode="out-in">
            <b-form-group
              :label="'Repeat ' + repeatSchedule + ' Until'"
              class="mt-2"
              label-for="repeatUntil"
              key="repeatUntilNonCustom"
              v-if="
                repeatSchedule == 'Daily' ||
                repeatSchedule == 'Weekly' ||
                repeatSchedule == 'Monthly'
              "
            >
              <b-form-datepicker
                v-model="repeatUntil"
                :min="todayDate"
                :placeholder="'Repeat ' + repeatSchedule + ' Until'"
                class="rounded"
                locale="en"
              />
            </b-form-group>

            <b-form-group
              label="Custom Repeat Dates & Time"
              class="mt-2"
              label-for="repeatUntilCustom"
              key="repeatUntilCustom"
              v-if="repeatSchedule == 'Custom'"
            >
              <flat-pickr
                v-model="repeatUntilCustom"
                id="repeatUntilCustom"
                inputId="repeatUntilCustom"
                placeholder="Custom Repeat Dates & Time"
                class="form-control"
                :config="{
                  mode: 'multiple',
                  enableTime: true,
                  altInput: true,
                  altFormat: 'D, M d, Y h:m K',
                  dateFormat: 'Z',
                  minDate: todayDate,
                }"
              />
              <small
                >Remember you can make multiple selection of dates & time</small
              >
            </b-form-group>
          </transition>
        </div>
      </transition>


        <b-form-group label="Voice Message File (* accept audio files with .mp3 or .wav extension only)">
          <validation-provider
            #default="{ errors }"
            name="Message"
            vid="message"
            rules="required"
          >
            <div class="d-flex align-items-center flex-column flex-md-row message-formater">
              <b-form-file
                id="message"
                v-model="message"
                accept="audio/mp3,audio/wav"
                input-id="message"
                placeholder="Choose an audio file or drop it here..."
                drop-placeholder="Drop audio file here..."
                @change="updateAudio"
              />
              <audio class="ml-md-4 mb-2 mb-md-0" id="preview-audio" controls="controls"></audio>
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
    


      <b-form-group class="mb-2">
        <b-form-checkbox
          id="status"
          v-model="status"
          name="status"
          :value="true"
        >
          I certify that this message abides by anti-spamming rules, restrictions on political messaging as well as international and local law and regulations. I have accepted the terms of use that govern the use of Pepea SMS™
        </b-form-checkbox>
        <small class="text-danger" v-if="!status && status != 'start'"
          >Agree to above terms and conditions to proceed</small
        >
      </b-form-group>

      <!-- Form Actions -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-modal.review-message
          :disabled="invalid || formInvalid || (!status || status == 'start')"
          :class="{ disabled: invalid }"
        >
          <b-spinner small v-if="formIsLoading" class="mr-1" />
          Submit
        </b-button>
      <voice-review @send-message="sendVoice" :messageAudio="messageAudio" ></voice-review>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormRadio,
  BFormCheckbox,
  BFormTags,
  BFormFile,
  BFormDatepicker,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import FlatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import VoiceReview from "./VoiceReview.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from '@themeConfig';


export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BFormTags,
    BFormFile,
    BFormDatepicker,
    BButton,
    BSpinner,
    FlatPickr,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
    VoiceReview,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const { appUrl } = $themeConfig.app;
    const { voicePhoneNumber } = $themeConfig.app;

    return {
      allContactsCount: 0,
      selectAllContacts: null,
      recipentAddingMethod: null,
      country: null,
      countries:[],
      phoneNumbers: [],
      contacts: [],
      allContacts: [],
      groups: [],
      allgroups: [],
      file: null,
      schedule: null,
      later: null,
      initialMessage: null,
      repeatSchedule: null,
      repeatUntil: null,
      repeatUntilCustom: null,
      todayDate: new Date(today),
      message: null,
      messageDuration: 0,
      messageAudio:null,
      status: "start",
      formIsLoading: false,
      appUrl: appUrl,
      voicePhoneNumber: voicePhoneNumber,
      required,
    };
  },
  computed: {
    formInvalid() {
      return (
        this.formIsLoading ||
        (this.selectAllContacts == false &&
          (this.phoneNumbers.length == 0 || !this.country) &&
          this.contacts.length == 0 &&
          this.groups.length == 0 &&
          (this.file == null || !this.country)) ||
        (this.schedule == "later" && this.later == null) ||
        (this.schedule == "repeat" &&
          (this.initialMessage == null || this.repeatSchedule == null)) ||
        (this.schedule == "repeat" &&
          (this.repeatSchedule == "Daily" ||
            this.repeatSchedule == "Weekly" ||
            this.repeatSchedule == "Monthly") &&
          this.repeatUntil == null) ||
        (this.schedule == "repeat" &&
          this.repeatSchedule == "Custom" &&
          (this.repeatUntilCustom == "" || this.repeatUntilCustom == null) )
      );
    },
  },
  watch: {
    recipentAddingMethod() {
      this.country = null;
      this.phoneNumbers = [];
      this.contacts = [];
      this.groups = [];
      this.file = null;
    },
    selectAllContacts(newVal) {
      if (newVal) {
        this.recipentAddingMethod = null;
      }
    },
    schedule(){
      this.later = null;
      this.initialMessage = null;
      this.repeatSchedule = null;
    },
    repeatSchedule(newVal, oldVal) {
      if (
        newVal === null ||
        ((newVal == "Daily" || newVal == "Weekly" || newVal == "Monthly") &&
          oldVal == "Custom") ||
        ((oldVal == "Daily" || oldVal == "Weekly" || oldVal == "Monthly") &&
          newVal == "Custom")
      ) {
        this.repeatUntil = null;
        this.repeatUntilCustom = null;
      }
    },
  },
  methods: {
    sendVoice() {
      this.formIsLoading = true;
      let formData = new FormData();
      if(this.selectAllContacts){
        formData.append('select_all_contacts', this.selectAllContacts);
      }
      if(this.recipentAddingMethod){
        formData.append('recipent_adding_method', this.recipentAddingMethod);
      }
      if(this.country){
        formData.append('country', this.country);
      }
      if(this.phoneNumbers.length && this.phoneNumbers.length > 0){
        for (const phoneNumbers of this.phoneNumbers) {
          formData.append('phone_numbers', phoneNumbers);
        }
      }
      if(this.contacts.length && this.contacts.length > 0){
        for (const contacts of this.contacts) {
          formData.append('contacts', contacts);
        }
      }
      if(this.groups.length && this.groups.length > 0){
        for (const groups of this.groups) {
          formData.append('groups', groups);
        }
      }
      if(this.file){
        formData.append('file', this.file);
      }
      if(this.schedule){
        formData.append('schedule', this.schedule);
      }
      if(this.later){
        formData.append('later', this.later);
      }
      if(this.initialMessage){
        formData.append('initial_message', this.initialMessage);
      }
      if(this.repeatSchedule){
        formData.append('repeat_schedule', this.repeatSchedule);
      }
      if(this.repeatUntil){
        formData.append('repeat_until', new Date(this.repeatUntil).toISOString());
      }
      if(this.repeatUntilCustom != "" && this.repeatUntilCustom != null){
        for (const repeatUntilCustom of this.repeatUntilCustom.split(", ")) {
          formData.append('repeat_until_custom', repeatUntilCustom);
        }
      }
      if(this.message){
        formData.append('message', this.message);
      }
      if(this.messageDuration){
        formData.append('message_duration', this.messageDuration);
      }
      this.$http.post('/voice/send',formData,
      {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Voice Message Sending Request Successfully Recieved.",
            icon: "CheckIcon",
            variant: "success",
            text: response.data.message,
          },
        });
        this.resetEverything();
        this.$nextTick(() => {
          this.$refs.messageValidator.reset();
        });
        this.formIsLoading = false;
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
        this.formIsLoading = false;
      });
    },
    filterContacts(search, loading) {
      if (search == "") {
        this.allContacts = [];
        return;
      }
      loading(true);
      this.$http
        .get("/contacts/only-search?search=" + search)
        .then((response) => {
          this.allContacts = [];
          for (const contact of response.data.data) {
            this.allContacts.push(contact.code + contact.phone);
          }
          loading(false);
        });
    },
    resetEverything() {
      this.selectAllContacts = null;
      this.recipentAddingMethod = null;
      this.country = null;
      this.phoneNumbers = [];
      this.contacts = [];
      this.allContacts = [];
      this.groups = [];
      this.file = null;
      this.schedule = null;
      this.later = null;
      this.initialMessage = null;
      this.repeatSchedule = null;
      this.repeatUntil = null;
      this.repeatUntilCustom = null;
      this.message = null;
      this.messageDuration = 0;
      this.messageAudio = null;
      this.status = "start";
    },
    updateAudio(e){
      var files = e.target.files;
      var sound = document.getElementById('preview-audio');
      if(files[0]){
        sound.src = URL.createObjectURL(files[0]);
        this.messageAudio = URL.createObjectURL(files[0]);
        sound.addEventListener('loadedmetadata', () => {
            var duration = sound.duration;
            this.messageDuration = Math.ceil(duration);
        },false);
        sound.onend = function(e) {
          URL.revokeObjectURL(this.src);
          URL.revokeObjectURL(this.messageAudio);
        }
      } else {
        sound.src = null;
        this.messageAudio = null;
      }
    }
  },
  created() {
    this.$http.get("/contacts/count").then((response) => {
      this.allContactsCount = response.data.data;
    });

    this.$http
      .get("/contact-group/search")
      .then((response) => {
        for (const group of response.data.data) {
          let pushGroup = {
            value: group.ID,
            text: group.name,
          };
          this.allgroups.push(pushGroup);
        }
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      });

    this.$http
      .get("/countries")
      .then((response) => {
        for (const country of response.data.data) {
          let pushCountry = {
            value: country.ID,
            text: country.title + " (" + country.code + ")",
          };
          this.countries.push(pushCountry);
        }
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.voice-phone-number-form-group {
  max-width: 15rem;
}
@media(min-width: 768px){
  .message-formater > div{
    width: 30rem !important;
  }
}

#preview-audio{
  height:40px;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in-out;
}
</style>