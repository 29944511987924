<template>
  <b-modal
    id="review-message"
    title="Review Message"
    centered
    ok-title="Send"
    ok-variant="primary"
    cancel-title="Cancel"
    cancel-variant="secondary"
    @ok="sendMessage"
  >
    <!-- hide-footer -->

    <b-form-group label="Message" >
      <div class="d-flex justify-content-center">
        <audio id="preview-audio-review"  :src="messageAudio" controls="controls"></audio>
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormTextarea } from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props:["messageAudio"],
  emits: ["send-message"],
  methods: {
    sendMessage() {
      this.$emit("send-message");
    },
  },
};
</script>