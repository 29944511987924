<template>
  <b-card no-body>
    <b-row class="m-2">
      <b-col>
        <h2>Compose & Schedule The Voice Message</h2>
        <p class="my-1">Compose a voice message & schedule the time you want it to be sent.</p>
        <hr />
        <!-- Body -->
       
        <compose-voice-form></compose-voice-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ComposeVoiceForm from "./components/ComposeVoiceForm.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    ComposeVoiceForm,
  }
};
</script>
